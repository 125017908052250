import { Component, OnInit } from '@angular/core';

import { NotificationsService } from 'app/layout/components/navbar/navbar-notification/notifications.service';
import Swal from 'sweetalert2';

// Interface
interface notification {
  messages: [];
  systemMessages: [];
  system: Boolean;
}

@Component({
  selector: 'app-navbar-notification',
  templateUrl: './navbar-notification.component.html'
})
export class NavbarNotificationComponent implements OnInit {
  // Public
  public notifications: any;
  public unreadNotifications: any;
  public payment_received: any;
  public intervalId;

  /**
   *
   * @param {NotificationsService} _notificationsService
   */
  constructor(private _notificationsService: NotificationsService) { }

  // Lifecycle Hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {
    this.getNotificationsData()
    this.intervalId = setInterval(() => this.getNotificationsData(), 60000);
  }

  getNotificationsData(){
    const userData = localStorage.getItem("currentUser");
    const userId = JSON.parse(userData || "{}").id
    this._notificationsService.getNotificationData().subscribe((res: any) => {
      if (res) {
        this.notifications = res.data
          // .filter((item: any) => item.userId == userId)
          // .sort((a: any, b: any) => new Date(b.date).getTime() - new Date(a.date).getTime());
      
        this.unreadNotifications = this.countNotifications(this.notifications);
      }
    }, (err) => {
      Swal.fire("Oh snap!", "Well, this is unexpected... An error has occurred while processing your request. Please try again after sometime.", "error");
    })
  }

  countNotifications(data: any) {
    var count = 0
    data.forEach((element: any) => {
      if (!element.read_status) {
        count++
      }
    })
    return count
  }

  readAllnotifications(){
    const userData = localStorage.getItem("currentUser");
    const userId = JSON.parse(userData || "{}").id
    this._notificationsService.readAllNotificationData().subscribe((res: any) => {
      if (res) {
        Swal.fire(
          "Notifications Read",
          "All notification cleared successfully",
          "success"
        );
        this.getNotificationsData()
      }
    }, (err) => {
      Swal.fire("Oh snap!", "Well, this is unexpected... An error has occurred while processing your request. Please try again after sometime.", "error");
    })
  }

  ngOnDestroy(): void {
    // Clear the interval to prevent memory leaks when the component is destroyed
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
  }

}
