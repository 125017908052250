import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-auth-logout',
  templateUrl: './auth-logout.component.html',
  styleUrls: ['./auth-logout.component.scss']
})
export class AuthLogoutComponent implements OnInit {

  constructor(
    private _router: Router,
    private _authenticationService: AuthenticationService,
  ) {}

  ngOnInit(): void {
    this._authenticationService.logout();
    this._router.navigate(['/auth/login']);
  }

}
